<template>
  <div class="sellRetail">
    <BannerImg :img-url="$StaticFileUrl + '/mp/BannerImage/v4_qyfl_banner.jpg'">
      <div class="title wow fadeInDown" data-wow-duration="1s">万鲸SAAS福利商城解决方案</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">为企业客户提供领先的技术系统，一站式供应整合与全场景运营服务，<br>提升员工体验和满意度</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">立即体验</div>
      </div>
    </BannerImg>
  </div>
</template>

<script>
import WOW from 'wowjs'
import BannerVideo from '@/components/BannerVideo'

/**
 * @author  XuHongli
 * @date  2022/12/28 10:12
 * @version 1.0
 * @description
 */
import BannerImg from '@/components/BannerImg'
export default {
  name: 'SellRetail',
  components: {
    BannerImg,
  },
  mounted() {
    new WOW.WOW().init()
  }
}
</script>

<style scoped>

</style>
